import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard"
  }, [_c("el-collapse", {
    staticClass: "app-container",
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    staticClass: "cc-mar-b-10",
    attrs: {
      name: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", {
    staticClass: "report-category"
  }, [_vm._v("\n          QE\n        ")])]), _vm._v(" "), _c("div", {
    staticClass: "report-list-wrapper"
  }, _vm._l(_vm.tableData, function (card, index) {
    return _c("div", {
      key: index,
      staticClass: "report-item"
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("div", {
      staticClass: "report-card-header"
    }, [_c("span", {
      staticClass: "report-title"
    }, [_c("el-link", {
      on: {
        click: function click($event) {
          return _vm.openCard(card.ID, card.name);
        }
      }
    }, [_vm._v(_vm._s(card.name))])], 1)])]), _vm._v(" "), _c("div", {
      staticClass: "report-card-body"
    }, [_c("div", {
      staticClass: "report-card-left"
    }, [_c("img", {
      staticStyle: {
        width: "2.6vw",
        height: "2.6vw"
      },
      attrs: {
        alt: "",
        src: require("@/assets/form.png"),
        fit: "fill"
      }
    })]), _vm._v(" "), _c("div", {
      staticClass: "report-card-right"
    }, [_c("div", {
      staticClass: "item"
    }, [_vm._v(_vm._s(card.description))])])]), _vm._v(" "), _c("div", {
      staticClass: "report-card-footer"
    }, [_c("div", {
      staticClass: "content"
    }, [_vm._v("Form")])])])], 1);
  }), 0)], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };